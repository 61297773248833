/**	
 * https://github.com/maxlutzfl/googlemapsjqueryplugin
 */ 

(function($) {

	/** Template: 
		$('.map').bcore_google_maps({
			api_key: theme.googleapi,
			lat: 0,
			lng: 0,
			zoom: 13,
			marker_icon: 'marker.png',
			marker_icon_w: 20,
			marker_icon_h: 20,
			styles: null,
			cluster: false,
			markers: [  ]
			infowindow_template: function(infowindow_data) {  },
			on_infowindow_open: function(cb) {  },
			on_infowindow_close: function(cb) {  },
			on_marker_click: function(cb) {  }
		});
	*/

})(jQuery);