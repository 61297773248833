;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var initSkrollr = function() {
		if (!isMobile()) {
			var s = skrollr.init({
				forceHeight: false,
				smoothScrolling: true,
				smoothScrollingDuration: 200
			});
		} else {
			$('html').addClass('no-skrollr');
		}
	}

	$(document).ready(function() {

		initSkrollr();

		$('html').removeClass('no-js').addClass('js');

		$('.album-list').magnificPopup({
			mainClass: 'mfp-fade',
			delegate: 'a', 
			type: 'image',
			titleSrc: 'title',
			gallery: {
				enabled: true
			}
		});
	});

})(jQuery);